import React from 'react'
import loadable from "@loadable/component"
import { GatsbySeo, BreadcrumbJsonLd} from 'gatsby-plugin-next-seo';
import * as styles from '../../styles/blogs.module.css'
import FooterCard from '../../components/cards/FooterCard/FooterCard';
const Layout = loadable(()=>import("../../hoc/Layout/Layout"));
const Card = loadable(()=> import('../../components/cards/Card/Card'));
const BlogList = loadable(()=> import('../../components/list/BlogList/BlogList'));

export default function blogs() {
    return (
        <Layout>
            <GatsbySeo
                language='en'
                title='Blogs - Raysummee'
                canonical='https://www.raysummee.com/blogs'
                description="Hey! Angshuman is here. Welcome to my Blogs. I write blogs about new and old technologies and computer stuffs. I am a flutter developer, full staker and UI/UX designer. I have developed Cizor, Crena, Raylex, Raylex studio, animore and many other apps"
                openGraph={{
                    url: 'https://www.raysummee.com/blogs',
                    title: 'Blogs - Raysummee',
                    type: 'website',
                    description: "Hey! Angshuman is here. Welcome to my website Raysummee. I am a flutter developer, full staker and UI/UX designer. I have developed Cizor, Crena, Raylex, Raylex studio, animore and many other apps",
                    images: [
                        {
                            url: 'https://www.raysummee.com/images/logo_wb.jpg',
                            width: 800,
                            height: 600,
                            alt: 'Logo',
                        }
                    ],
                    site_name: 'Raysummee',
                }}
            />
            <BreadcrumbJsonLd
                itemListElements={[
                    {
                        position: 1,
                        name: 'Blogs',
                        item: 'https://www.raysummee.com/blogs',
                    },
                ]}
            />
            <div className={styles.scrollable}>
                <Card className={styles.blog_card}>
                    <BlogList/>
                </Card>
                <FooterCard className={styles.footer_card}/>
            </div>
        </Layout>
    )
}
